<template>
  <div v-if="$isLoading">
    <div class="bg-white p-3 mt-3">
      <b-row class="no-gutters">
        <b-col>
          <SEOSection
            :data="form.product.translationList"
            :img="imageLogoLang"
            :langActive="languageActive"
            :isValidate="$v.form.product.translationList"
            :v="$v.form.product.translationList"
            @onDataChange="onDataChange"
          />
        </b-col>
      </b-row>
    </div>

    <div class="bg-white p-3 mt-3">
      <b-row class="pl-1" v-if="form.product.productTypeId == 4">
        <b-col cols="6">
          <div class="panel">
            <b-form-checkbox
              size="lg"
              id="sameLang"
              class="mt-0 mt-sm-3"
              @change="useSameLanguage"
              v-model="form.product.isSameLanguage"
              >ใช้เหมือนกันทุกภาษา
            </b-form-checkbox>
          </div>
        </b-col>

        <b-col cols="6" class="text-right">
          <div class="panel mt-2">
            <b-button
              type="button"
              class="btn btn-language"
              v-for="(language, index) in languageList"
              v-bind:key="index"
              v-bind:class="[languageActive == language.id ? 'active' : '']"
              @click="changeLanguage(language.id, index)"
              :disabled="form.product.isSameLanguage ? true : false"
            >
              <span class="text-uppercase">{{ language.nation }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div
            v-for="(item, index) in form.product.translationList"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <b-row class="mt-3">
                <b-col>
                  <TextEditor
                    v-if="item.description != null"
                    textFloat="คำอธิบาย"
                    :rows="8"
                    :value="item.description"
                    :name="'description_' + item.languageId"
                    :img="imageLogoLang"
                    placeholder="Type something..."
                    isRequired
                    :v="
                      $v.form.product.translationList.$each.$iter[index]
                        .description
                    "
                    @onDataChange="(val) => (item.description = val)"
                    :imgTypeId="imgTypeId"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6"></b-col>
        <b-col md="6" class="text-sm-right">
          <button
            type="button"
            @click="checkForm()"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึก
          </button>
        </b-col>
      </b-row>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import TextEditor from "@/components/inputs/TextEditor";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import Vue from "vue";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import SEOSection from "@/views/pages/product/components/SEOSection";

export default {
  components: {
    TextEditor,
    ModalLoading,
    ModalAlertError,
    SEOSection,
    ModalAlert,
  },
  validations: {
    form: {
      product: {
        translationList: {
          $each: {
            name: { required, minLength: minLength(3) },
            description: {
              required: requiredIf(function () {
                return this.form.product.productTypeId == 4;
              }),
            },
            metaTitle: { required },
            metaKeyword: { required },
            metaDescription: { required },
          },
        },
      },
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      languageList: [],
      isDisable: false,
      isLoadingData: false,
      imageLogoLang: "",
      languageActive: 1,
      form: null,
      imgTypeId: 15,
      modalMessage: "",
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getData();
    await this.changeLanguage(1, 0);
  },
  methods: {
    onDataChange(val, type) {
      if (val) this.form.product.seo[type] = val;
    },
    getData: async function () {
      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language `,
        null,
        this.$headers,
        null
      );

      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/product/productDetail/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.form.product.display = true;
        this.form.product.enabled = true;
        if (this.id == 1) {
          this.form.product.flagIsAutoPriceRatio = true;
        }
        this.$v.form.$reset();

        if (this.id == 0) {
          this.form.product.productTypeId = 1;
          this.form.product.attributes = [];
          this.form.product.children = { attributeOption: [] };
          this.form.product.productAttributeImage = [];
        }

        if (this.form.product.gp == 0) {
          this.form.product.gp = this.form.product.productGP;
        }

        this.isLoadingData = true;

        if (this.form.product.isSameLanguage) {
          this.imageLogoLang = "";
          this.languageActive = this.form.product.mainLanguageId;
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.form.product.mainLanguageId);

          if (index == -1) index = 1;

          this.imageLogoLang = this.languageList[index || 1].imageUrl;
        }
        this.$isLoading = true;
      }
    },
    changeLanguage(id, index) {
      if (!this.form.product.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.product.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.product.mainLanguageId = this.languageActive;
          let data = this.form.product.translationList.filter(
            (val) => val.languageId == this.form.product.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              for (
                let index = 0;
                index < this.form.product.translationList.length;
                index++
              ) {
                this.form.product.translationList[index].name = data.name;
                this.form.product.translationList[index].description =
                  data.description;
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.product.translationList.filter(
            (val) => val.languageId != this.form.product.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.description = "";
              data.name = "";
            }
          }
        }
      });
    },
    checkForm: async function () {
      if (this.form.product.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        await this.checkValidateTranslationList();
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 250
            );
          }
        });
        return;
      }
      this.submit();
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.product.translationList.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.product.translationList.$model[index].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/ProductDetail/Description/Save`,
        null,
        this.$headers,
        this.form
      );

      this.isDisable = false;
      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .card {
  margin-bottom: 0 !important;
}
</style>
